/* Features.css */
.features-container {
  padding: 4rem 2rem;
  background-color: #1a1a2e; /* Constant navy blue background */
  text-align: center;
  min-height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  scroll-margin-top: 100px; /* Adjust this value to the height of your navbar */
}

.features-heading {
  font-size: 3rem;
  font-weight: 700;
  color: #ffd700; /* Gold text for heading */
  margin-bottom: 2rem;
  position: relative;
}

.features-heading::after {
  content: '';
  width: 60px;
  height: 5px;
  background-color: #ff69b4; /* Pink color underline */
  display: block;
  margin: 0.5rem auto 0;
  border-radius: 2px;
}

.features {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: center;
  width: 100%;
  max-width: 1200px; /* Increased maximum width */
}

.feature {
  flex: 1 1 45%; /* Each feature takes up about 45% of the width */
  margin: 1rem;
  background-color: #2a2a5e; /* Slightly lighter navy blue background for feature cards */
  padding: 2rem;
  border-radius: 12px; /* Increased border-radius for rounded corners */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
  text-align: left; /* Align text to the left */
  transition: transform 0.3s ease-in-out; /* Add transition for hover effect */
  color: #ffffff; /* White text for contrast */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature:hover {
  transform: translateY(-10px); /* Slight lift on hover */
}

.feature-title {
  font-size: 2rem; /* Increased font size */
  font-weight: 600;
  color: #ff69b4; /* Pink text for titles */
  margin-bottom: 1rem;
  text-align: center; /* Center the title text */
}

.feature-description {
  font-size: 1.25rem;
  color: #ffffff; /* White text for descriptions */
  line-height: 1.6;
  text-align: center; /* Center the description text */
}

.feature-image {
  width: 100%;
  height: auto;
  border-radius: 12px; /* Rounded corners for images */
  margin-bottom: 1rem;
}

@media (max-width: 800px) {
  .features {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    flex: 1 1 100%; /* Each feature takes up full width on small screens */
    margin: 1rem 0;
  }

  .feature-title {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .feature-description {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}
