html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.campus-craze {
  background: linear-gradient(135deg, #0a0a23, #1c1c46);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffd700;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 100px; /* Add padding to the bottom */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.logo {
  max-width: 100%; /* Ensure logo fits within the viewport */
  height: auto;
  margin: 0 auto 20px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.modern-button {
  margin: 10px;
  padding: 1rem 3rem;
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  outline: none;
  border: 2px solid #ffd700;
  border-radius: 1rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.modern-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ffd700, #ffda00);
  transition: transform 0.3s ease;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
}

.modern-button:hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

.modern-button:hover {
  color: #2a2a5e;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.parties-container {
  width: 100%;
  max-width: 1200px;
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-bottom: 60px; 
  
}

.parties-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding-right: 20px; /* Add some padding on the right */
}

.party-card {
  border: none;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #2a2a5e;
  color: #ffd700;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  height: 400px;
  box-sizing: border-box;
}

.party-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.elite-party {
  border: 2px solid #ffd700;
  background: linear-gradient(135deg, #ff69b4, #ffd700);
  color: #000;
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.5);
}

.free-party {
  background-color: #2a2a5e;
  color: #ffd700;
}

.party-image-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.party-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.party-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: left;
}

.party-date {
  font-size: 1.2em;
  font-weight: bold;
}

.party-date span:first-child {
  display: block;
}

.party-name {
  font-size: 1.5em;
  margin-top: 5px;
}

.party-university {
  font-size: 1.1em;
  margin-top: 2px;
}

.party-details {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.like-section {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 15px;
}

.heart-icon {
  font-size: 24px;
  color: grey;
  cursor: pointer;
}

.liked {
  color: red;
}

.heart-icon:hover {
  color: darkred;
}

.like-section span {
  font-size: 1.2em;
  color: #ffd700;
  margin-left: 5px;
}

.rsvp-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rsvp-section p {
  margin: 0;
  font-size: 1em;
  color: #ffd700;
}

.rsvp-button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: #ff69b4;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.rsvp-button:hover {
  background-color: #ffd700;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.rsvp-button.cancel {
  background-color: #dc3545;
}

.rsvp-button.cancel:hover {
  background-color: #c82333;
}

.delete-button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: #dc3545;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c82333;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #ffd700;
}

.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.filter-container select,
.filter-container input[type="date"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.8);
  color: black;
  font-size: 1em;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(255, 255, 255, 0.8) url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="none" stroke="%23000" stroke-width=".5" d="M2 0L0 2h4z"/></svg>') no-repeat right 10px center;
  background-size: 10px;
  padding-right: 30px;
}

/* Responsive Design */

@media (max-width: 1200px) {
  .campus-craze {
    padding: 15px;
  }

  .parties-container {
    padding: 15px;
  }

  h3 {
    font-size: 1.3em;
  }

  .logo {
    width: 200px;
  }

  .parties-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .campus-craze {
    padding: 10px;
  }

  .parties-container {
    padding: 10px;
  }

  .modern-button {
    padding: 0.8rem 2.5rem;
    font-size: 0.9rem;
  }

  h3 {
    font-size: 1.2em;
  }

  .logo {
    width: 150px;
  }

  .filter-container {
    flex-direction: column;
  }

  .filter-container select,
  .filter-container input[type="date"] {
    width: 100%;
    max-width: 300px;
  }

  .parties-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .campus-craze {
    padding: 5px;
  }

  .parties-container {
    padding: 5px;
  }

  .modern-button {
    padding: 0.6rem 2rem;
    font-size: 0.8rem;
  }

  h3 {
    font-size: 1em;
  }

  .logo {
    width: 100px;
  }

  .filter-container {
    flex-direction: column;
    align-items: center;
  }

  .filter-container select,
  .filter-container input[type="date"] {
    width: 100%;
    max-width: 250px;
    margin-bottom: 10px;
  }

  .parties-grid {
    grid-template-columns: 1fr;
  }
}
