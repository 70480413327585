body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

.profile-swipe {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #0a0a23, #1c1c46); /* Slightly darker navy blue gradient */
  min-height: 100vh;
  padding: 20px;
  padding-top: calc(env(safe-area-inset-top) + 20px); /* Additional padding for safe area */
  padding-bottom: calc(env(safe-area-inset-bottom) + 20px); /* Additional padding for safe area at bottom */
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; /* Enable vertical scrolling */
}

.back-button-profileswipe {
  align-self: flex-start;
  background-color: #ff69b4; /* Pink background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

.back-button-profileswipe:hover {
  background-color: #ffd700; /* Gold color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.filter-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.filter-container .react-select__control {
  padding: 10px 20px;
  border-radius: 25px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  max-width: 220px;
}

.filter-container .react-select__control:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 105, 180, 0.5); /* Pink focus outline */
}

.filter-container button {
  background: linear-gradient(135deg, #ff69b4, #ff1493); /* Gradient pink background */
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px; /* Add margin at the top to create space between the filters and the button */
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* For hover effect */
  overflow: hidden;
}

.filter-container button:hover {
  background: linear-gradient(135deg, #ffd700, #ffdf00); /* Gold gradient on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.filter-container button:active {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.filter-container button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  border-radius: 25px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.filter-container button:hover:before {
  opacity: 1;
}

.filter-container button span {
  position: relative;
  z-index: 1;
}

.profile-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.profile-card {
  background: #2a2a5e; /* Slightly lighter navy blue background for profile cards */
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  padding: 20px;
  text-align: center;
  width: 100%;
  color: white; /* White text for contrast */
}

.profile-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  padding-bottom: 100px; /* Ensure space at the bottom */
}

.profile-actions input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  max-width: 300px;
  font-size: 16px; /* Ensure the font size is 16px or greater */
}

.profile-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease;
  color: white;
  font-weight: bold;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skip-button {
  background-color: #ff6347; /* Red background */
  position: relative;
  overflow: hidden;
}

.skip-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ff4500, #ff6347); /* Gradient hover background */
  transition: transform 0.3s ease;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
}

.skip-button:hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

.skip-button:hover {
  color: #fff; /* Change text color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}

.direct-message-button {
  background-color: #32cd32; /* Green background */
  position: relative;
  overflow: hidden;
}

.direct-message-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #228b22, #32cd32); /* Gradient hover background */
  transition: transform 0.3s ease;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
}

.direct-message-button:hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

.direct-message-button:hover {
  color: #fff; /* Change text color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}

.no-profiles {
  text-align: center;
  color: white;
  background: linear-gradient(135deg, #1a1a2e, #2a2a5e); /* Navy blue gradient background */
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.no-profiles h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffd700; /* Gold color for heading */
}

.no-profiles p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.retry-button-profileswipe {
  background-color: #ff69b4; /* Pink background */
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.retry-button-profileswipe:hover {
  background-color: #ffd700; /* Gold color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1200px) {
  .profile-card-container {
    max-width: 600px;
  }

  .profile-actions input {
    max-width: 200px;
  }

  .profile-actions button {
    min-width: 100px;
  }
}

@media (max-width: 768px) {
  .profile-actions {
    flex-direction: column;
  }

  .profile-actions input {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
  }

  .profile-actions button {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .profile-card-container {
    max-width: 100%;
    padding: 0 10px;
  }

  .profile-actions input {
    max-width: none;
    margin-bottom: 10px;
  }

  .profile-actions button {
    width: 100%;
    max-width: none;
  }
}
