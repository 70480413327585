body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.home {
  background: linear-gradient(135deg, #0a0a23, #1c1c46); /* Slightly darker navy blue gradient */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffd700; /* Gold color for text */
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto; /* Ensure scrolling within the container */
}

.home-content {
  max-width: 800px;
  width: 100%;
  text-align: center;
  color: #ffd700; /* Gold color for text */
  padding: 20px;
  box-sizing: border-box;
  flex-grow: 1; /* Allow content to grow and push footer down */
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 3em;
  color: #ffd700; /* Gold color for heading */
  margin-bottom: 20px;
  animation: fadeInDown 1s ease;
}

.auth-forms,
.logged-in {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-forms input {
  margin: 10px;
  padding: 15px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modern-button {
  margin: 10px;
  padding: 1rem 3rem; /* Increased padding */
  background: linear-gradient(135deg, #ff69b4, #ff1493); /* Gradient background */
  outline: none;
  border: 2px solid #ffd700; /* Gold outline */
  border-radius: 1rem; /* More rounded corners */
  font-size: 1rem; /* Smaller text size */
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease; /* Smooth transitions */
  text-transform: uppercase; /* Uppercase text */
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.modern-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ffd700, #ffda00); /* Gradient hover background */
  transition: transform 0.3s ease;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
}

.modern-button:hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

.modern-button:hover {
  color: #2a2a5e; /* Change text color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}

.logged-in p {
  margin: 10px;
  color: #ffd700; /* Gold color for text */
  font-size: 1.5em;
  animation: fadeInUp 1s ease;
}

.navigation-images {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap; /* Wrap images for smaller screens */
}

.navigation-images img {
  margin: 15px;
  width: 300px; /* Adjusted size */
  height: auto;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 15px; /* Added border radius for a modern look */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: #2a2a5e; /* Slightly lighter navy blue background for feature cards */
}

.navigation-images img:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

/* Adding glow effects with animation */
.navigation-images img#campusCrushImage {
  animation: pinkGlow 1.5s infinite alternate;
}

.navigation-images img#campusCrazeImage {
  animation: goldGlow 1.5s infinite alternate;
}

@keyframes pinkGlow {
  from {
    box-shadow: 0 0 5px #ff69b4, 0 0 10px #ff69b4, 0 0 15px #ff69b4, 0 0 20px #ff69b4;
  }
  to {
    box-shadow: 0 0 10px #ff69b4, 0 0 20px #ff69b4, 0 0 30px #ff69b4, 0 0 40px #ff69b4;
  }
}

@keyframes goldGlow {
  from {
    box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700;
  }
  to {
    box-shadow: 0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffd700, 0 0 40px #ffd700;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */

@media (max-width: 1200px) {
  .home-content {
    max-width: 600px;
    padding: 15px;
  }

  .navigation-images img {
    width: 250px;
  }

  h1 {
    font-size: 2.5em;
  }
}

@media (max-width: 768px) {
  .home-content {
    max-width: 90%;
  }

  .navigation-images img {
    width: 200px;
  }

  .modern-button {
    padding: 0.8rem 2.5rem;
    font-size: 0.9rem;
  }

  h1 {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .home {
    padding: 10px;
  }

  .home-content {
    max-width: 100%;
    padding: 10px;
    padding-bottom: 100px; /* Add padding to the bottom */
  }

  .navigation-images img {
    width: 150px;
  }

  .modern-button {
    padding: 0.6rem 2rem;
    font-size: 0.8rem;
  }

  h1 {
    font-size: 1.5em;
  }

  .logged-in p {
    font-size: 1.2em;
  }
}
