body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #1a1a2e, #2a2a5e); /* Navy blue gradient background */
}

.party-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #0a0a23, #1c1c46); /* Slightly darker navy blue gradient */
  min-height: 100vh;
  color: white;
  font-family: 'Roboto', sans-serif;
  position: relative;
  overflow-y: auto; /* Ensure scrolling within the container */
  padding-bottom: 70px; /* Add padding to the bottom */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  z-index: 1000;
}

.party-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 600px;
  margin-top: 50px; /* Ensure there's enough space for the back button */
}

.party-form h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
  color: #ffd700; /* Gold color for heading */
}

.party-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.party-form input,
.party-form textarea,
.party-form select {
  margin: 10px;
  padding: 15px;
  width: 100%;
  border: none;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.8);
  color: black;
  font-size: 1em;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.party-form input[type="date"],
.party-form input[type="time"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(255, 255, 255, 0.8);
  padding-right: 15px;
}

.party-form input[type="date"]:focus,
.party-form input[type="time"]:focus {
  background: rgba(255, 255, 255, 1);
}

.party-form select {
  -webkit-appearance: none; /* Remove default dropdown arrow for Webkit browsers */
  -moz-appearance: none; /* Remove default dropdown arrow for Firefox */
  appearance: none; /* Remove default dropdown arrow */
  background: rgba(255, 255, 255, 0.8) url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="none" stroke="%23000" stroke-width=".5" d="M2 0L0 2h4z"/></svg>') no-repeat right 10px center; /* Custom arrow icon */
  background-size: 10px;
  padding-right: 30px; /* Space for arrow */
}

.party-form button {
  margin: 15px 0;
  padding: 1rem 3rem; /* Increased padding */
  background-color: #ff69b4; /* Pink background */
  outline: none;
  border: none;
  border-radius: 0.5rem; /* Slightly rounded corners */
  font-size: 1rem; /* Smaller text size */
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  text-transform: uppercase; /* Uppercase text */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

.party-form button:hover {
  background-color: #ffd700; /* Gold color on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a shadow on hover */
}

.back-button-partyform {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #ff69b4; /* Pink background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

.back-button-partyform:hover {
  background-color: #ffd700; /* Gold color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.custom-file-input {
  display: none; /* Hide the default file input */
}

.file-input-label {
  display: inline-block;
  margin: 10px;
  padding: 15px;
  width: calc(100% - 30px);
  border: none;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.8);
  color: black;
  font-size: 1em;
  box-sizing: border-box; /* Ensures padding is included in width */
  text-align: center;
  cursor: pointer;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .party-form {
    padding: 20px;
    margin-top: 80px; /* Adjust to ensure there's enough space for the back button */
  }

  .back-button-partyform {
    top: 10px;
    left: 10px;
    padding: 8px 16px;
  }

  .party-form h1 {
    font-size: 2em;
  }

  .party-form button {
    padding: 0.8rem 2rem;
    font-size: 0.9rem;
  }
}

.error-message {
  color: #ff0000; /* Red color for the error message */
  margin-bottom: 10px;
  font-size: 1em;
  text-align: center;
}
