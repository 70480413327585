/* Add these styles to PartyCard.css */

.party-card {
  border: none; /* No border */
  margin: 10px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #2a2a5e; /* Slightly lighter navy blue background */
  color: #ffd700; /* Gold color for text */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 400px; /* Adjust width as needed */
  max-height: 600px; /* Set a maximum height */
}

.party-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.elite-party {
  border: 2px solid #ffd700; /* Gold border for elite parties */
  background: linear-gradient(135deg, #ff69b4, #ffd700); /* Pink to Gold gradient background */
  color: #ffd700; /* Gold text color */
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.5); /* Gold shadow */
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700;
  }
  to {
    box-shadow: 0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffd700, 0 0 40px #ffd700;
  }
}

.free-party {
  background-color: #2a2a5e; /* Navy blue background for free parties */
  color: #ffd700; /* Gold text color */
}

.party-image-container {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  position: relative;
  overflow: hidden;
}

.party-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.like-section {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 15px;
}

.heart-icon {
  font-size: 24px;
  color: grey;
  cursor: pointer;
  margin-right: 5px;
}

.liked {
  color: red;
}

.heart-icon:hover {
  color: darkred;
}

.like-section span {
  font-size: 1.2em;
  color: #ffd700; /* Gold color for text */
}

.party-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay for text */
  color: white;
  text-align: left;
}

.party-date {
  display: flex;
  font-size: 1.2em;
  font-weight: bold;
  gap: 0.2em;
}

.party-name {
  font-size: 1.5em;
  margin-top: 5px;
}

.party-university {
  font-size: 1.1em;
  margin-top: 2px;
}

.party-details {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background for details */
  color: white;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail-item .detail-icon {
  font-size: 24px; /* Larger icon size */
  margin-right: 10px;
}

.detail-item p {
  margin: 0;
  font-size: 1em;
  color: #ffd700; /* Gold color for text */
}

.party-description {
  max-height: 100px; /* Limit the height of the description */
  overflow-y: auto; /* Add vertical scroll if needed */
}

.rsvp-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rsvp-section p {
  margin: 0;
  font-size: 1em;
  color: #ffd700; /* Gold color for text */
}

.rsvp-button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: #ff69b4; /* Pink background */
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow transition */
}

.rsvp-button:hover {
  background-color: #ffd700; /* Gold color on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a shadow on hover */
}

.rsvp-button.cancel {
  background-color: #dc3545;
}

.rsvp-button.cancel:hover {
  background-color: #c82333;
}

.delete-button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: #dc3545;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c82333;
}

.view-rsvp-button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: #007bff; /* Blue background */
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-rsvp-button:hover {
  background-color: #0056b3;
}
