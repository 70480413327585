.faq-container {
  padding: 4rem 2rem;
  background-color: #1a1a2e; /* Constant navy blue background */
  text-align: center;
  min-height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  scroll-margin-top: 100px; /* Adjust this value to the height of your navbar */
}

.faq-heading {
  font-size: 3rem;
  font-weight: 700;
  color: #ffd700; /* Gold text for heading */
  margin-bottom: 2rem;
  position: relative;
}

.faq-heading::after {
  content: '';
  width: 60px;
  height: 5px;
  background-color: #ff69b4; /* Pink color underline */
  display: block;
  margin: 0.5rem auto 0;
  border-radius: 2px;
}

.faq-list {
  width: 100%;
  max-width: 800px;
}

.faq-item {
  background-color: #2a2a5e; /* Navy blue background for FAQ items */
  padding: 1.5rem;
  margin: 1rem 0;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease-in-out; /* Add transition for hover effect */
  color: #ffffff; /* White text for contrast */
}

.faq-item:hover {
  transform: translateY(-5px); /* Slight lift on hover */
}

.faq-question {
  font-size: 1.75rem;
  font-weight: 600;
  color: #ff69b4; /* Pink text for questions */
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-icon {
  font-size: 1.5rem;
}

.faq-answer {
  font-size: 1.25rem;
  color: #cccccc; /* Light grey text for answers */
  line-height: 1.6;
}

@media (max-width: 800px) {
  .faq-heading {
    font-size: 2.5rem;
  }

  .faq-question {
    font-size: 1.5rem;
  }

  .faq-answer {
    font-size: 1.1rem;
  }
}
