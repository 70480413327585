body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.membership-container {
  text-align: center;
  padding: 20px;
  min-height: 100vh; /* Ensure it takes the full height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #0a0a23, #1c1c46);
  position: relative;
  box-sizing: border-box;
  overflow-y: auto; /* Ensure scrolling within the container */
}

h1 {
  font-size: 3em;
  color: #ffd700;
  margin-bottom: 20px;
}

p {
  font-size: 1.5em;
  color: #ffd700;
  margin-bottom: 20px;
}

.modern-button {
  margin: 10px;
  padding: 1rem 3rem;
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  outline: none;
  border: 2px solid #ffd700;
  border-radius: 1rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.modern-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ffd700, #ffda00);
  transition: transform 0.3s ease;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
}

.modern-button:hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

.modern-button:hover {
  color: #2a2a5e;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.back-button-membership {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #ff69b4; /* Pink background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  z-index: 10; /* Ensure the button stays on top */
}

.back-button-membership:hover {
  background-color: #ffd700; /* Gold color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.membership-status {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 15px;
  border: 2px solid #ffd700; /* Gold border */
  margin-bottom: 50px; /* Ensure there is space for the logout button */
}

.upgrade-box {
  background-color: #333;
  color: white;
  padding: 40px; /* Increased padding for more space */
  border-radius: 10px;
  border: 2px solid #ffd700;
  text-align: center; /* Center the text */
  max-width: 400px; /* Increased max-width for a larger box */
  margin: 0 auto;
}

.upgrade-box h2 {
  color: #ffd700;
  margin-bottom: 15px;
}

.upgrade-box .price {
  color: #ffd700;
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  border: 2px solid #ffd700;
  padding: 10px;
  border-radius: 5px;
  background-color: #2a2a5e;
}

.upgrade-box .perks-list {
  list-style: none;
  padding: 0;
  text-align: center; /* Center the perks list */
  margin-bottom: 20px;
}

.upgrade-box .perks-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  color: #ffd700;
}

.upgrade-box .perks-list li:before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #ffd700;
}

.warning-text {
  color: #ff0000; /* Red color for warning text */
  font-size: 1em;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .membership-container h1 {
    font-size: 2.5em;
  }

  .membership-container p {
    font-size: 1.2em;
  }

  .modern-button {
    font-size: 1.1em;
  }
}

@media (max-width: 768px) {
  .membership-container h1 {
    font-size: 2em;
  }

  .membership-container p {
    font-size: 1em;
  }

  .modern-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .membership-container {
    padding: 10px;
    padding-bottom: 100px; /* Add padding to the bottom */
  }

  .membership-container h1 {
    font-size: 1.5em;
  }

  .membership-container p {
    font-size: 1em;
  }

  .modern-button {
    padding: 0.6rem 2rem;
    font-size: 0.8rem;
  }

  .membership-status {
    padding: 15px;
    margin-bottom: 70px; /* Ensure there is enough space for the logout button */
  }

  .upgrade-box {
    padding: 20px;
    max-width: 90%;
  }

  .back-button-membership {
    top: 10px;
    left: 10px;
    padding: 8px 16px;
    font-size: 0.8rem;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  z-index: 1000;
}