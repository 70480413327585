body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

.profile-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  padding-bottom: 80px;
  box-sizing: border-box;
  width: 100%;
  background: linear-gradient(135deg, #0a0a23, #1c1c46);
  overflow-x: hidden;
}

.profile-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border-radius: 15px;
  background: #2a2a5e;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  color: white;
  box-sizing: border-box;
  animation: fadeInForm 1s ease-in-out;
}

@keyframes fadeInForm {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.profile-step {
  animation: fadeInStep 0.5s ease-in-out;
}

@keyframes fadeInStep {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Loading screen styles */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
  transition: opacity 0.3s ease-in-out;
}

.profile-step label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 18px;
}

@keyframes goldToPinkGlow {
  0%, 100% {
    box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700;
  }
  50% {
    box-shadow: 0 0 5px #ff69b4, 0 0 10px #ff69b4, 0 0 15px #ff69b4, 0 0 20px #ff69b4;
  }
}

.profile-form input,
.profile-form textarea,
.profile-form select {
  margin-top: 10px;
  padding: 15px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.3);
  color: white;
  outline: none;
  box-shadow: 0 0 5px #ffd700, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700;
  animation: goldToPinkGlow 3s infinite alternate;
  font-size: 16px;
}

.profile-form input::placeholder,
.profile-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}

.profile-form select {
  color: black;
}

.profile-form select option:disabled {
  color: rgba(255, 255, 255, 0.8);
}

.profile-form textarea {
  resize: vertical;
  height: 150px;
  font-size: 16px;
}

.picture-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.picture-preview {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ffd700;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.picture-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picture-preview:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.custom-file-input {
  display: none;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-navigation button {
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  background-color: #ff69b4;
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.3s ease;
}

.form-navigation button:hover {
  background-color: #ffd700;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.profile-step button[type="submit"] {
  margin-top: 20px;
  padding: 20px 40px;
  border: none;
  border-radius: 25px;
  background-color: #ff69b4;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.3s ease;
  align-self: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-step button[type="submit"]:hover {
  background-color: #ffd700;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.toggle-switch {
  width: 60px;
  height: 30px;
  background: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease;
}

.toggle-slider {
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
}

.toggle-slider.on {
  transform: translateX(30px);
}

.toggle-slider.off {
  transform: translateX(0);
}

.toggle-switch.on {
  background: #4caf50;
}

.toggle-switch.off {
  background: #ccc;
}

.campus-crush-description {
  margin-top: 10px;
  font-size: 14px;
  color: #ffd700;
}

@media (max-width: 1200px) {
  .profile-form {
    width: 80%;
  }

  .profile-form input,
  .profile-form textarea,
  .profile-form select {
    width: 100%;
  }

  .form-navigation button {
    min-width: 100px;
  }
}

@media (max-width: 768px) {
  .profile-form {
    width: 90%;
    padding: 20px;
  }

  .form-navigation {
    flex-direction: column;
  }

  .form-navigation button {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .profile-form {
    width: 95%;
    padding: 10px;
  }

  .form-navigation button {
    width: 100%;
    max-width: none;
  }
}
