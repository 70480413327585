.initial-screen {
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.initial-screen h1 {
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
}

.initial-screen p {
  margin-bottom: 30px;
  font-size: 18px;
}

.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #757575;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.google-signin-button:hover {
  background-color: #f1f1f1;
}

.google-signin-button img.google-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

