/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #1a1a2e; /* Navy blue background */
}

/* Hero section styles */
.home-banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 1rem;
  background-color: #1a1a2e; /* Navy blue background */
  width: 100%;
  height: 100vh;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  position: relative;
}

.home-text-section {
  text-align: center;
  max-width: 800px; /* Set a max width */
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1rem; /* Add padding for small screens */
}

.primary-heading {
  font-size: clamp(2.5rem, 4vw, 4rem); /* Adjusted size for better visibility */
  font-weight: 700;
  margin-bottom: 1rem; /* Adjusted margin */
  color: #ffd700; /* Gold color for the heading */
}

.highlight {
  color: #ff69b4; /* Pink color for the highlight */
  font-style: italic; /* Optionally, make it italic */
  font-weight: 900; /* Make it bolder */
}

.primary-text {
  font-size: clamp(1.25rem, 2vw, 1.5rem); /* Adjusted size for better readability */
  line-height: 1.5; /* Added line height for spacing */
  margin-bottom: 2rem; /* Adjusted margin */
  max-width: 800px; /* Ensure the text doesn't stretch too wide */
}

/* Button styles */
.auth-button {
  padding: 1rem 3rem; /* Increased padding */
  background: linear-gradient(135deg, #ff69b4, #ffd700); /* Pink to Gold gradient background */
  outline: none;
  border: none;
  border-radius: 0.5rem; /* Slightly rounded corners */
  font-size: 1rem; /* Smaller text size */
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
  text-transform: uppercase; /* Uppercase text */
  margin: 0.5rem; /* Add margin between buttons */
}

.auth-button:hover {
  transform: scale(1.05); /* Slightly increase the size on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a shadow on hover */
}

/* Button group container */
.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem; /* Space between buttons */
}

@media (max-width: 800px) {
  .home-text-section {
    padding: 1rem;
  }

  .primary-heading {
    font-size: clamp(2rem, 5vw, 3rem); /* Adjusted for smaller screens */
  }

  .primary-text {
    font-size: clamp(1rem, 3vw, 1.25rem); /* Adjusted for smaller screens */
  }

  .auth-button {
    font-size: 0.875rem; /* Smaller text size for smaller screens */
    padding: 0.9rem 2rem; /* Adjusted padding for smaller screens */
  }
}

@media (max-width: 600px) {
  .home-text-section {
    padding: 1rem;
    max-width: 90%;
  }

  .primary-heading {
    font-size: clamp(1.75rem, 5vw, 2.5rem); /* Adjusted for smaller screens */
  }

  .primary-text {
    font-size: clamp(0.875rem, 3vw, 1rem); /* Adjusted for smaller screens */
  }

  .auth-button {
    font-size: 0.75rem; /* Smaller text size for smaller screens */
    padding: 0.8rem 1.5rem; /* Adjusted padding for smaller screens */
  }
}
