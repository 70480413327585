body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

.campus-crush {
  background: linear-gradient(135deg, #0a0a23, #1c1c46); /* Slightly darker navy blue gradient */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffd700; /* Gold color for text */
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 20px;
}

.logo {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  animation: fadeInDown 1s ease;
}

.campus-crush-content {
  background: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeInUp 1s ease;
}

.modern-button {
  margin: 10px;
  padding: 1rem 3rem; /* Increased padding */
  background: linear-gradient(135deg, #ff69b4, #ff1493); /* Gradient background */
  outline: none;
  border: 2px solid #ffd700; /* Gold outline */
  border-radius: 1rem; /* More rounded corners */
  font-size: 1rem; /* Smaller text size */
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease; /* Smooth transitions */
  text-transform: uppercase; /* Uppercase text */
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.modern-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ffd700, #ffda00); /* Gradient hover background */
  transition: transform 0.3s ease;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
}

.modern-button:hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

.modern-button:hover {
  color: #2a2a5e; /* Change text color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .campus-crush {
    padding: 15px;
  }

  .logo {
    width: 80%;
  }

  .modern-button {
    padding: 0.8rem 2.5rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .campus-crush {
    padding: 10px;
  }

  .logo {
    width: 60%;
  }

  .modern-button {
    padding: 0.6rem 2rem;
    font-size: 0.8rem;
  }
}

.small-s {
  font-size: 0.8em; /* Slightly smaller */
  vertical-align: baseline; /* Aligns with the text baseline */
}


