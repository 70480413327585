body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #0a0a23, #1c1c46);
  color: #ffd700;
  height: 100vh;
  overflow-y: auto;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #ffd700;
}

.loading-screen p {
  font-size: 2em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.profile-view h1 {
  margin-bottom: 20px;
  font-size: 2.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.profile-view .profile-details,
.profile-view .profile-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 800px;
  background: #2a2a5e;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  box-sizing: border-box;
}

.profile-view .profile-details p,
.profile-view .profile-edit input,
.profile-view .profile-edit textarea,
.profile-view .profile-edit select {
  color: #ffffff;
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  font-size: 16px;
}

.profile-view .profile-edit select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.profile-view .profile-edit option {
  color: #000000;
}

.profile-view .profile-edit input::placeholder,
.profile-view .profile-edit textarea::placeholder {
  color: #ffffff;
  font-size: 16px;
}

.profile-view .profile-edit label {
  width: 100%;
  margin-bottom: 5px;
  color: #ffd700;
  text-align: left;
}

.profile-view .profile-pictures {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.profile-view .profile-picture {
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 15px;
  object-fit: cover;
  border: 2px solid #ffd700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.profile-view .profile-picture:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.profile-view button,
.profile-view .modern-button {
  margin: 10px;
  padding: 1rem 3rem;
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  outline: none;
  border: 2px solid #ffd700;
  border-radius: 1rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.profile-view button:before,
.profile-view .modern-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ffd700, #ffda00);
  transition: transform 0.3s ease;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
}

.profile-view button:hover:before,
.profile-view .modern-button:hover:before {
  transform: scaleX(1);
  transform-origin: left;
}

.profile-view button:hover,
.profile-view .modern-button:hover {
  color: #2a2a5e;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.picture-inputs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  width: 100%;
}

.picture-preview {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ff69b4;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(255, 105, 180, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}

.picture-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.picture-preview:hover {
  transform: scale(1.05);
  border: 2px solid #ff69b4;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.picture-preview span {
  font-size: 36px;
  color: #ffd700;
  font-weight: bold;
}

.remove-picture {
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
}

.remove-picture:hover {
  background-color: #ff1a1a;
}

.file-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.file-input-label {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 25px;
  background-color: #ff69b4;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: background 0.3s ease, transform 0.3s ease;
}

.file-input-label span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.file-input-label:hover {
  background: #ffd700;
  transform: translateY(-3px);
}

.custom-file-input {
  display: none;
}

/* Responsive Design */

@media (max-width: 768px) {
  .profile-view {
    padding: 10px;
  }

  .profile-view h1 {
    font-size: 2em;
  }

  .profile-view .profile-details,
  .profile-view .profile-edit {
    padding: 20px;
  }

  .profile-view .profile-details p,
  .profile-view .profile-edit input,
  .profile-view .profile-edit textarea,
  .profile-view .profile-edit select {
    padding: 10px;
    margin-bottom: 15px;
  }

  .profile-view button,
  .profile-view .modern-button {
    padding: 0.8rem 2.5rem;
    font-size: 0.9rem;
  }

  .file-input-label {
    padding: 10px;
    font-size: 14px;
  }

  .picture-inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .profile-view {
    padding: 5px;
  }

  .profile-view h1 {
    font-size: 1.8em;
  }

  .profile-view .profile-details,
  .profile-view .profile-edit {
    padding: 15px;
  }

  .profile-view .profile-details p,
  .profile-view .profile-edit input,
  .profile-view .profile-edit textarea,
  .profile-view .profile-edit select {
    padding: 8px;
    margin-bottom: 12px;
  }

  .profile-view button,
  .profile-view .modern-button {
    padding: 0.6rem 2rem;
    font-size: 0.8rem;
  }

  .file-input-label {
    padding: 8px;
    font-size: 12px;
  }

  .picture-inputs {
    grid-template-columns: 1fr;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2em;
  z-index: 9999;
}
