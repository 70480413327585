* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px; /* Adjust the height to fit the logo */
  padding: 0 1rem;
  background-color: #1a1a2e; /* Navy blue background */
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0; /* Adjust this value to shift the navbar */
}

.nav-logo-container img {
  max-width: 150px; /* Adjust the max-width if needed */
  height: auto;
}

.navbar-menu-container {
  display: none;
}

.navbar-links-container a {
  margin-right: 1.5rem;
  text-decoration: none;
  color: #ffd700; /* Gold color for links */
  font-size: 1.1rem;
  font-weight: 600;
  transition: color 0.2s; /* Smooth transition for hover effect */
}

.navbar-links-container a:hover {
  color: #ff69b4; /* Pink color on hover */
}

.primary-button {
  padding: 0.7rem 1.5rem;
  background-color: #ff69b4; /* Pink background */
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: background-color 0.2s, box-shadow 0.2s; /* Smooth transition for hover effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.primary-button:hover {
  background-color: #ffd700; /* Gold color on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a shadow on hover */
}

.navbar-cart-icon {
  font-size: 1.15rem;
  color: #ffd700; /* Gold color for cart icon */
}

.navbar-cart-icon:hover {
  color: #ff69b4; /* Pink color on hover */
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }

  .primary-button {
    font-size: 1rem;
  }
}

@media (max-width: 800px) {
  .nav-logo-container img {
    max-width: 140px;
  }

  .navbar-links-container {
    display: none;
  }

  .navbar-menu-container {
    display: flex;
  }
}
