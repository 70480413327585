body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #0a0a23, #1c1c46); /* Slightly darker navy blue gradient */
  padding: 20px;
  padding-top: calc(env(safe-area-inset-top) + 20px); /* Additional padding for safe area */
  padding-bottom: calc(env(safe-area-inset-bottom) + 20px); /* Additional padding for safe area at bottom */
  box-sizing: border-box;
  justify-content: space-between;
  overflow-y: auto; /* Enable vertical scrolling */
}

@media (min-width: 1024px) {
  .chat-container {
    flex-direction: row;
  }
}

.chat-header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #ffd700; /* Gold bottom border */
  margin-bottom: 10px;
}

.back-arrow {
  background: none;
  border: none;
  color: #ffd700; /* Gold color */
  cursor: pointer;
  margin-right: 10px;
}

.back-arrow-icon {
  width: 24px;
  height: 24px;
}

.header-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  border: 2px solid #ffd700; /* Gold border */
}

.header-profile-name {
  font-size: 1.2rem;
  color: #ffd700; /* Gold color */
  margin-right: 10px;
}

.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.chat-box {
  flex: 1;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 15px;
  overflow-y: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  border: 2px solid #ffd700; /* Gold border */
}

.message {
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
  max-width: 70%;
  transition: transform 0.3s ease;
}

.message.sent {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.message.received {
  align-self: flex-start;
}

.message-content {
  background: #e3e3e3;
  border-radius: 15px;
  padding: 10px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.message.sent .message-content {
  background: #ffd700; /* Gold background */
  color: black; /* Black text */
}

.message.received .message-content {
  background: #ff69b4; /* Pink background */
  color: #ffd700; /* Gold text color */
  border: 2px solid #ffd700; /* Gold border */
}

.message p {
  margin: 0;
  font-size: 1rem;
  color: black; /* Ensure the text is black */
}

.message span {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6); /* Light grey text color for timestamp */
}

.message.sent span {
  color: rgba(0, 0, 0, 0.6); /* Black text for timestamp */
}

.message.received span {
  color: rgba(255, 215, 0, 0.6); /* Lighter gold text color for timestamp */
}

.message:hover {
  transform: scale(1.02);
}

.message-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  border: 2px solid #ffd700; /* Gold border */
}

.message.sent .message-profile-pic {
  margin-left: 10px;
  margin-right: 0;
}

.input-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #ffd700; /* Gold top border */
  background: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  margin-top: auto;
}

.input-box input {
  flex: 1;
  padding: 15px;
  border: 2px solid #ffd700; /* Gold border */
  border-radius: 15px;
  margin-right: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #2a2a5e; /* Slightly lighter navy blue */
  color: #ffd700; /* Gold text color */
  font-size: 16px; /* Ensure the font size is 16px or greater */
}

.input-box button {
  padding: 15px;
  border: none;
  border-radius: 15px;
  background: #ff69b4; /* Pink background */
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid #ffd700; /* Gold border */
}

.input-box button:hover {
  background: #ffd700; /* Gold color on hover */
}

.view-profile-button {
  background: #ff69b4; /* Pink background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  border: 2px solid #ffd700; /* Gold border */
}

.view-profile-button:hover {
  background: #ffd700; /* Gold color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.profile-card-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 170px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.close-profile-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #ff69b4; /* Pink background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #ffd700; /* Gold border */
}

.close-profile-button:hover {
  background: #ffd700; /* Gold color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Responsive Design */

@media (max-width: 1200px) {
  .chat-container {
    padding: 15px;
  }

  .input-box input {
    padding: 12px;
  }

  .input-box button {
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .chat-container {
    padding: 10px;
  }

  .input-box input {
    padding: 10px;
  }

  .input-box button {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .chat-container {
    padding: 10px;
  }

  .chat-content {
    margin-right: 0;
  }

  .chat-box {
    padding: 15px;
  }

  .input-box {
    padding: 10px;
  }

  .input-box input {
    padding: 10px;
  }

  .input-box button {
    padding: 10px;
  }
}
