.about-container {
  padding: 4rem 2rem;
  background-color: #1a1a2e; /* Constant navy blue background */
  text-align: center;
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  scroll-margin-top: 100px; /* Adjust this value to the height of your navbar */
}
@media (max-width: 800px) {
  .about-container {
    scroll-margin-top: 230px; /* Adjust this value based on the navbar height on smaller screens */
  }
}
.about-heading {
  font-size: 3rem;
  font-weight: 700;
  color: #ffd700; /* Gold text for heading */
  margin-bottom: 1.5rem;
  position: relative;
}

.about-heading::after {
  content: '';
  width: 60px;
  height: 5px;
  background-color: #ff69b4; /* Pink color underline */
  display: block;
  margin: 0.5rem auto 0;
  border-radius: 2px;
}

.about-text {
  font-size: 1.25rem;
  color: #cccccc; /* Light grey text for better contrast */
  line-height: 1.8;
  margin-bottom: 1.5rem;
  max-width: 800px; /* Ensure the text doesn't stretch too wide */
  text-align: justify; /* Justify text for a cleaner look */
}

@media (max-width: 800px) {
  .about-heading {
    font-size: 2.5rem;
  }

  .about-text {
    font-size: 1.1rem;
  }
}
