body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  overflow: auto; /* Allow scrolling for the entire page */
}

.inbox {
  background: linear-gradient(135deg, #0a0a23, #1c1c46); /* Slightly darker navy blue gradient */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 60px;
}

.inbox-content {
  background: rgba(10, 10, 35, 0.9); /* Darker background for contrast */
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 800px;
  color: white;
  margin: 0 auto;
  animation: fadeInUp 1s ease;
  overflow-y: auto; /* Enable vertical scrolling for the inbox content */
  max-height: calc(100vh - 40px); /* Adjust max-height to fit within the viewport */
  border: 2px solid #ff69b4; /* Add pink border */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow */

}

.inbox h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #ffd700; /* Gold color for heading */
  font-size: 2em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.chat {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  margin: 10px 0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  border-left: 5px solid #ff69b4; /* Pink border on the left */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.chat:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
}

.chat-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid #ffd700; /* Gold border */
  background-color: #444; /* Fallback background color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chat-participants {
  font-weight: bold;
  color: #ff69b4; /* Pink color for participants */
  font-size: 1.2em;
}

.chat-timestamp {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  align-self: flex-start;
}

.message-preview {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
}

.back-button-inbox {
  align-self: flex-start;
  background-color: #ff69b4; /* Pink background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.back-button-inbox:hover {
  background-color: #ffd700; /* Gold color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.more-icon-inbox {
  color: #ffd700 !important; /* Gold color */
  transition: color 0.3s ease;
}

.more-icon-inbox:hover {
  color: #ff69b4 !important; /* Pink color on hover */
}

.delete-button {
  background: linear-gradient(135deg, #ff4d4d, #ff0000);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.delete-button:hover {
  background: linear-gradient(135deg, #ff0000, #ff4d4d);
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.delete-menu {
  border-radius: 10px;
}

.delete-menu-item {
  color: white;
  font-weight: bold;
  padding: 10px 20px;
}

.delete-menu-item:hover {
  background: rgba(255, 0, 0, 0.3);
}

.no-messages {
  text-align: center;
  color: white;
  background: linear-gradient(135deg, #0a0a23, #1c1c46); /* Matching the gradient with the rest of the app */
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.no-messages h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffd700; /* Gold color for heading */
}

.no-messages p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.retry-button, .back-button {
  background: linear-gradient(135deg, #ff69b4, #ff7e5f); /* Pink background */
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.retry-button:hover, .back-button:hover {
  background: linear-gradient(135deg, #ff7e5f, #ff69b4); /* Pink color on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .inbox-content {
    padding: 15px;
  }

  .inbox h1 {
    font-size: 1.8em;
  }

  .chat {
    padding: 10px;
  }

  .profile-picture {
    width: 50px;
    height: 50px;
  }

  .chat-info {
    font-size: 0.9rem;
  }

  .chat-participants {
    font-size: 1rem;
  }

  .message-preview {
    font-size: 0.85rem;
  }

  .back-button-inbox {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .retry-button, .back-button {
    padding: 12px 24px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .inbox-content {
    padding: 10px;
  }

  .inbox h1 {
    font-size: 1.5em;
  }

  .chat {
    padding: 8px;
  }

  .profile-picture {
    width: 40px;
    height: 40px;
  }

  .chat-info {
    font-size: 0.8rem;
  }

  .chat-participants {
    font-size: 0.9rem;
  }

  .message-preview {
    font-size: 0.75rem;
  }

  .back-button-inbox {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .retry-button, .back-button {
    padding: 10px 20px;
    font-size: 0.8rem;
  }
}
