/* RSVPModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: linear-gradient(135deg, #0a0a23, #1c1c46); /* Slightly darker navy blue gradient */
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    position: relative;
    color: white; /* Ensure text is visible on the dark background */
  }
  
  .close-button {
    background: #ff69b4;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  