.cancellation-container {
    text-align: center;
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #0a0a23, #1c1c46);
  }
  
  h1 {
    font-size: 3em;
    color: #ffd700;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.5em;
    color: #ffd700;
    margin-bottom: 20px;
  }
  
  .modern-button {
    margin: 10px;
    padding: 1rem 3rem;
    background: linear-gradient(135deg, #ff69b4, #ff1493);
    outline: none;
    border: 2px solid #ffd700;
    border-radius: 1rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .modern-button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #ffd700, #ffda00);
    transition: transform 0.3s ease;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right;
  }
  
  .modern-button:hover:before {
    transform: scaleX(1);
    transform-origin: left;
  }
  
  .modern-button:hover {
    color: #2a2a5e;
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  