/* Profile card container */
.profile-card {
  background: #2a2a5e; /* Slightly lighter navy blue background for profile cards */
  border-radius: 20px; /* Increased border-radius for smoother corners */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
  padding: 25px; /* Increased padding */
  margin: 20px auto;
  max-width: 600px; /* Adjusted the max-width */
  text-align: center;
  color: white; /* White text for contrast */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.profile-card:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.profile-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  border-radius: 20px 20px 0 0; /* Rounded top corners */
  background: linear-gradient(90deg, #ff69b4, #ffd700); /* Pink and gold gradient */
}

/* Name styling */
.profile-card h2 {
  margin-bottom: 15px; /* Increased margin */
  font-size: 2.5em; /* Increased font size */
  font-weight: 900; /* Make the name bolder */
  color: #ffd700; /* Gold color for heading */
  text-transform: uppercase; /* Make the name uppercase */
}

/* Swipeable views container */
.swipeable-container {
  position: relative;
  margin-bottom: 15px; /* Increased margin */
  width: 100%;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  max-height: 400px; /* Increased the max-height */
  border-radius: 15px;
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

/* Arrow button styling */
.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 105, 180, 0.8); /* Pink background */
  color: white;
  border: none;
  padding: 12px; /* Increased padding */
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  font-size: 20px; /* Increased font size */
  transition: background 0.3s ease; /* Smooth transition for hover effect */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.left-arrow {
  left: 15px; /* Adjusted positioning */
}

.right-arrow {
  right: 15px; /* Adjusted positioning */
}

/* Profile details section */
.profile-details {
  text-align: left;
  color: white; /* White text for contrast */
  margin-top: 15px; /* Increased margin */
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligned to flex-start */
  width: 100%;
}

.detail-item {
  display: flex;
  align-items: center;
  margin: 8px 0; /* Increased margin */
  font-size: 1.1em; /* Increased font size */
}

.detail-icon {
  margin-right: 10px; /* Margin between icon and text */
  color: #ffd700; /* Gold color for icons */
}

/* Differentiating category titles */
.profile-details p {
  margin: 8px 0; /* Increased margin */
  font-size: 1.1em; /* Increased font size */
}

.profile-details .category-title {
  font-weight: 700; /* Make the category titles bold */
  color: #ffd700; /* Gold color for the category titles */
}

.profile-details .data {
  font-weight: 400; /* Keep the data text regular weight */
  color: #ffffff; /* White color for the data */
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .profile-card {
    max-width: 90%;
  }
  .image-container img {
    max-height: 380px;
  }
}

@media (max-width: 768px) {
  .profile-card {
    max-width: 95%;
    padding: 20px; /* Adjusted padding */
  }
  .profile-card h2 {
    font-size: 2em; /* Adjusted font size */
  }
  .image-container img {
    max-height: 360px;
  }
  .profile-details p {
    font-size: 1em; /* Adjusted font size */
  }
}

@media (max-width: 480px) {
  .profile-card {
    max-width: 100%;
    padding: 15px; /* Adjusted padding */
  }
  .profile-card h2 {
    font-size: 1.8em; /* Adjusted font size */
  }
  .image-container img {
    max-height: 340px;
  }
  .profile-details p {
    font-size: 0.9em; /* Adjusted font size */
  }
  .arrow-button {
    padding: 10px; /* Adjusted padding */
    font-size: 18px; /* Adjusted font size */
  }
}
