/* Signup.css */
.signup-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #1a1a2e;
  padding: 20px;
  box-sizing: border-box;
}

.signup-content {
  background: linear-gradient(135deg, #ff69b4, #ffd700); /* Pink to Gold gradient */
  padding: 2rem;
  border-radius: 16px; /* More rounded corners */
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Softer, larger shadow */
  max-width: 400px; /* Restrict content width */
  width: 100%;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffd700; /* Gold color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-text {
  color: #ff6b6b; /* Softer red for error text */
  margin-bottom: 1rem;
  font-weight: bold;
}
.signup-content .error-text {
  color: red; /* Softer red for error text */
  margin-bottom: 1rem;
  font-weight: bold;
}


.signup-content h1 {
  margin-bottom: 1.5rem;
  color: #ffd700; /* Gold color */
  font-size: 2rem; /* Larger heading */
  font-weight: 600;
}

.signup-content input {
  margin-bottom: 1rem;
  padding: 0.875rem;
  width: 100%; /* Full width inputs */
  border-radius: 20px; /* More rounded input boxes */
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 1rem; /* Larger font size for inputs */
}

.signup-content input:focus {
  border-color: #ffd700; /* Focus border color */
  box-shadow: 0 0 8px rgba(255, 215, 0, 0.5); /* Focus shadow effect */
}

.signup-content button {
  padding: 0.875rem;
  width: 100%; /* Full width button */
  border: none;
  border-radius: 20px; /* More rounded buttons */
  background-color: #ff69b4; /* Pink color */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s, box-shadow 0.2s;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
}

.signup-content button:hover {
  background-color: #ff85c1; /* Slightly lighter pink on hover */
  transform: translateY(-3px); /* Lift button on hover */
  box-shadow: 0 10px 20px rgba(255, 105, 180, 0.3); /* Soft shadow on hover */
}

.signup-content button:active {
  transform: translateY(1px); /* Press button down on click */
  box-shadow: 0 5px 10px rgba(255, 105, 180, 0.2); /* Reduce shadow on click */
}

.signup-content p {
  margin-top: 1.5rem;
  color: #ccc;
  font-size: 0.9rem;
}

.signup-content a {
  color: #ffd700; /* Gold color */
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.signup-content a:hover {
  color: #ffe066; /* Lighter gold on hover */
}
.success-text {
  color: #4caf50; /* Green color for success */
  margin-bottom: 1rem;
  font-weight: bold;
}
.signup-content .success-text {
  color: green; /* Green color for success */
  margin-bottom: 1rem;
  font-weight: bold;
}