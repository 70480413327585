.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1a1a2e; /* Navy blue background */
  color: white;
  padding: 20px; /* Added padding to avoid content touching the edges on smaller screens */
}

.login-content {
  background: linear-gradient(135deg, #ff69b4, #ffd700); /* Pink to Gold gradient */
  padding: 2rem;
  border-radius: 16px; /* Rounded corners */
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px; /* Set a max width to prevent over-expansion on larger screens */
  width: 100%; /* Ensure content adapts to smaller screens */
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffd700; /* Gold color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-content h1 {
  margin-bottom: 1rem;
  font-size: 2rem; /* Larger font size for the heading */
  color: #ffd700; /* Gold color */
}

.login-content .error-text {
  color: red; /* Softer red for error text */
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1rem; /* Slightly increased font size for better readability */
}

.login-content .success-text {
  color: green; /* Green color for success messages */
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1rem; /* Slightly increased font size for better readability */
}

.login-content form {
  display: flex;
  flex-direction: column;
}

.login-content input {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 20px; /* Rounded input boxes */
  border: none;
  outline: none;
  font-size: 1rem; /* Larger font size for inputs */
}

.login-content button {
  padding: 0.75rem;
  background-color: #ff69b4; /* Pink color */
  color: white;
  border: none;
  border-radius: 20px; /* Rounded buttons */
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  font-size: 1rem; /* Larger font size for buttons */
}

.login-content button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.login-content p {
  margin-top: 1rem;
  font-size: 1rem; /* Slightly larger font size for the text */
  color: #ffffff; /* White color for the text "Don't have an account?" */
}

.login-content a {
  color: #000000; /* Black color for the "Sign Up" hyperlink */
  text-decoration: underline;
  font-weight: bold; /* Bold to make the link stand out */
}

.login-content .forgot-password {
  background: none;
  border: none;
  color: white; /* White color */
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
  font-size: 1rem; /* Larger font size */
}
